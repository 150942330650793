import { IDetails } from '../interfaces/Details';

export const BackgroundData: IDetails[] = [
  {
    title: 'ЛГРЭС Лукомльская электростанция 2011-2021 г.',
    titleUrl: 'https://www.vitebsk.energo.by/ru/o-predpriyatii/filials/lukomlskaia-gres/',
    location: 'Беларусь, г.Новолукомль',
    items: [
      'Инженер электроник 1-категории группа информационных подсистем',
      'Обслуживание систем автоматического управления генераторных установок',
      'Мониторинг параметров',
      'Участвовал в вводе, наладке систем автоматического управления',
      'Программирование контроллеров систем автоматического управления и мониторинга',
    ],
  },
  {
    title: 'ЮНИДОРС производство межкомнатных дверей, окон и другое 2005-2010 г.',
    titleUrl: 'https://www.unidoors.by/',
    location: 'Беларусь, г.Новолукомль',
    items: ['Оператор станков  с ЧПУ ', 'Различные рабочие специальности'],
  },
];

export const EducationData: IDetails[] = [
  {
    title: ' Полоцкий государственный университет 2005-2010 г.',
    titleUrl: 'https://www.psu.by',
    location: 'Беларусь, Новополоцк',
    items: [
      'Факультет компьютерных наук и электроники',
      'Специальность: "Моделирование и компьютерное проектирование радиоэлектронных средств',
      'Инженер по радиоэлектронике',
    ],
  },
  {
    title: 'Витебский государственный политехнический колледж 2001-2005 г.',
    titleUrl: 'https://www.vgpt.vitebsk.by',
    location: 'Беларусь, Витебск',
    items: ['Специальность: "Проектирование и производство радиоэлектронных средств"', 'техник-технолог'],
  },
];
