import { FC } from 'react';

export const About: FC = () => {
  return (
    <div>
      <h3 className='page-title white-text'>About</h3>
      <div className='container'>
        <blockquote>
          Я Back-end, Full Stack Nodejs разработчик, которому интересно создавать новое, пробираться через ошибки и
          проблемы, постоянно обучаться. Чувствую себя наполненным восторгом и эйфорией, как ребенок, видя результат
          своих творений, осознавая полезность своих способностей.
        </blockquote>
        <p>
          Мне всегда нравилось программирование. Знакомство с ним началось в 2002 году с Pascal, html. Затем
          самостоятельно изучал Delphi, PHP, javascript, Mysql, linux. Пробовал многое, фантазии не было предела. Мне
          нравится создавать нечто - что в последствии работает без моего вмешательства, само по себе.
        </p>
        <blockquote>Я чувствую себя "создателем" в повседневной жизни.</blockquote>
        <p>
          Сталкиваясь с ошибками и проблемами, хочется узнать почему так произошло. Этот интерес исправить проблему
          ведет к новым знаниям и раздвигает кругозор. Чем сложнее проблема, тем интереснее ее решать! Это чувство
          постоянного непрекращающегося поиска, экспериментов, постоянного обучения.{' '}
        </p>
        <p>
          Все мое программирование было на уровне хобби. Я решил профессионально заняться web разработкой.
          Сосредоточился на web Node.js разработке. Мне интересно Back-end направление, но создавать законченное web
          приложение тоже приятно.
        </p>
        <blockquote>
          Моя цель найти команду разработчиков увлеченных своим делом, с искоркой в глазах что-бы подпитывать друг друга
          энергией, перенимая опыт коллег.
        </blockquote>
      </div>
    </div>
  );
};
